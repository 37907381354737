import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';
import menu from '../../img/menu.svg';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false,
    };
  }

  toggleHamburger = () => {
    this.setState({
      hamburgerActive: !this.state.hamburgerActive,
    });
  };

  render() {
    return (
      <nav>
        <div className='flex justify-between border-b-2 border-white py-6 mb-8 px-2'>
          <div className='block w-full justify-between p-4'>
            <div className='flex justify-between w-full'>
              <Link
                to='/'
                className='flex space-x-4 items-center justify-center'
                title='Logo'
              >
                <img
                  className='h-auto w-10 items-center'
                  src={'/../img/logo.png'}
                  alt='mrstockbot'
                />
                <div className='space-y-0'>
                  <span className='block text-base font-large text-lg font-black text-white items-center hover:text-purple-300'>
                    mr stock bot
                  </span>
                  <span className='block text-sm font-bold text-white'>
                    {' '}
                    no worries! lifestyle
                  </span>
                </div>
              </Link>
              {/* Hamburger menu */}
              <div className='block space-x-12 lg:hidden'>
                <button onClick={() => this.toggleHamburger()}>
                  <img
                    className='h-auto w-10 items-center'
                    src={menu}
                    alt='menu'
                  />
                </button>
              </div>
            </div>
            {this.state.hamburgerActive === true ? (
              <Fragment>
                <div className='bg-gray-600 w-full rounded-xl mx-auto justify-center text-center py-2 lg:hidden'>
                  <div className='grid grid-rows-4 mx-auto w-5/6 divide-y divide-white'>
                    <Link
                      className='text-lg text-white hover:text-purple-300 font-black py-2'
                      to='/about'
                    >
                      about
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-purple-300 font-black py-2'
                      to='/commands'
                    >
                      commands
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-purple-300 font-black py-2'
                      to='/privacy-policy'
                    >
                      privacy
                    </Link>
                    <Link
                      className='text-lg text-white hover:text-purple-300 font-black py-2'
                      to='/contact-us'
                    >
                      contact
                    </Link>
                  </div>
                </div>
              </Fragment>
            ) : (
              ''
            )}
          </div>
          <Fragment>
            {/* Navigation menu */}
            <div className='flex w-full justify-center space-x-12 items-center hidden lg:flex'>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-purple-300 font-black'
                to='/about'
              >
                about
              </Link>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-purple-300 font-black'
                to='/commands'
              >
                commands
              </Link>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-purple-300 font-black'
                to='/privacy-policy'
              >
                privacy
              </Link>
              <Link
                className='whitespace-nowrap text-lg text-white hover:text-purple-300 font-black'
                to='/contact-us'
              >
                contact
              </Link>
            </div>
          </Fragment>
        </div>
      </nav>
    );
  }
};

Navbar.propTypes = {
  hamburgerActive: PropTypes.bool,
};

export default Navbar;
