import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';

const TruncatedNavbar = class extends React.Component {
  render() {
    return (
      <nav>
        <div className='flex justify-between py-6 mb-8 px-2'>
          <div className='block w-full justify-between p-4'>
            <div className='flex justify-between w-full'>
              <Link
                to='/'
                className='flex text-lg text-green-dense hover:text-green-900 font-black py-2 items-center'
                title='Logo'
              >
                no worries! lifestyle | portfolio
              </Link>
              <Link
                className='flex text-lg text-green-dense hover:text-green-900 font-black py-2 items-center'
                to='/contact-us'
              >
                contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default TruncatedNavbar;
